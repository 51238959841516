.timeline {
    padding: 5rem 0;

    .slide {
        padding-right: 10rem;
    }
    &-item {
      
        span {
            width: 8    0%;
            display: block
        }
    }

    &-year {
        font-size: 12rem;
        font-weight: 700;
        color: #008bbb;
        letter-spacing: -5px;
    }
    &-title {
        margin-bottom: 1rem;
    }
    span {
        font-weight: 100;
    }

    // gliderprev-next

    .glider-prev,
    .glider-next {
        background: #008bbb;
        border-radius: 51px;
        width: 50px;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #Fff;
        opacity: 0.7;


        &.disabled {
            opacity: 0.2;
        }   
    }

}