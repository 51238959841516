.mainBanner {
    background: url(../../assets/images/pexels-brett-sayles-4373997.jpg) 0 0 no-repeat;
    background-size: cover;
    display: flex;
    height: 70vh;
    display: flex;
    align-items: center;
    position: relative;
    overflow: hidden; 

    .caption {
        display: flex;
        flex-direction: column;
        text-align: left;
        justify-content: flex-start;
        position: relative;
        color: #fff;

        
    }

    &::before {
        content: "  ";
        background-image: linear-gradient(rgba(0,0,0,0), rgba(0,0,0,.7));
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
    }
    // &::after {
    //     content: url(../../assets/images/curve-banner.svg);
    //     width: 100%;
    //     height: auto;
    //     position: absolute;
    //     bottom: -100px;
    // }
    

    h1  { 
        font-size: 2.5rem;
        margin-bottom: 1rem;
    }

    span { 
        font-size: 1.6rem;
        max-width: 100%;
        font-weight: 100;
        margin-bottom: 2rem;
    }
    
}

.innerBanner {
    height: 20vh;
    background-size: cover !important;
}

.bgWhatsApp { 
    background: url(../../assets/images/banner-contact.webp) center right no-repeat;
    background-size: cover;
    height: 46vh;
}

// banners internos
#quem-somos {
    background: url(../../assets/images/background-quemsomos.jpg) center center no-repeat;
}   

#servicos {
    background: url(../../assets/images/background-geometry.jpg) center center no-repeat;
}

#contato {
    background: url(../../assets/images/contact-us-background.jpg) center center no-repeat;
}


@media (min-width: 768px) {
    .mainBanner {
        height: 40vh;
    }
}

// media desktop
@media (min-width: 1366px) {

    .bgWhatsApp {
        height: 40vh;
    }

    .mainBanner {
        height: calc(100vh - 70px);

        h1  { 
            font-size: 5.6rem;
        }

        span {
            font-size: 3.2rem;
            max-width: 70%;
            margin-bottom: 1rem;
        }
    }
    .innerBanner {
        height: 350px;
    }
    .bgWhatsApp {
        height: 40vh;
    }
}