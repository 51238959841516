.arrowDown {
    position: absolute;
    bottom: 20%;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    z-index: 2;
}
.icon-arrow  {
    position: absolute;
    left: 50%;
    transform: translatX(-50%);
    bottom: 15%;
}