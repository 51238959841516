.services { 
    
    position: relative;
    padding: 5rem 0 3rem 0;

    // &::before {
    //     width: 100%;
    //     height: 100%;
    //     position: absolute;
    //     top: 0;
    //     content: " ";
    //     left: 0;
    //     background: rgba(0,0,0,.5);
    //     z-index: 0;
    // }

    h1 {
        text-align: center;
        z-index: 1;
        position: relative;
        margin-bottom: 3rem;
    }
}
.compServicesContainer {
    display: flex;
    align-items: flex-start;
    position: relative;
    z-index: 1;
}
.box-service {
    display: flex;
    align-items: center;
    width: 33.333%;
    .infos {
        width: 100%;
        padding: 0 1rem;
        h2 {

        }
        span {
            font-weight: 100;
            height: 80px;
            display: block;
        }

    }

    .image {
        width: 100%;
        height: 200px;
        margin-bottom: 2.5rem;
        border-radius: 8px;

       
    }
    h3 { 
        display: flex;
        height: auto;
        color: #008bbb;
        margin-bottom: 1rem;
        font-size: 1.6rem;
    }
    
    .first { 
        background: url(../../assets/images/cabeamento-estruturado-rfo.jpg) 0 0 no-repeat;
        background-size: cover;
    }
    .second { 
        background: url(../../assets/images/fusao-fibra-optica.png) 0 0 no-repeat;
        background-size: cover;
    }
    .third { 
        background: url(../../assets/images/rede-fibra-optica.jpg) 0 0 no-repeat;
        background-size: cover;
    }


    button {
        display: block;
    }
}

.spacer-fiber {
    width: auto;
    transform: rotate(170deg);
    top: -34%;
    position: absolute;
    left: -4vw;
    filter: drop-shadow(2px 0 32px rgba(0,0,0,.2));
}
@media (min-width: 768px) {
    .spacer-fiber {
        width: 80vw;
        transform: rotate(164deg);
        top: -57%;
    }
}
@media (min-width: 1366px) {

    .services {
        padding: 16rem 0 7rem 0;

        h1 {
            
        }
    }
    .box-service {
        .infos {
            padding: 7rem 3rem 3rem 0;
        }
    }

    .spacer-fiber {
        top: -68%;
    }
}