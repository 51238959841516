

@import url('https://fonts.googleapis.com/css2?family=Archivo:wght@400;500&family=Bricolage+Grotesque:opsz,wght@10..48,200;10..48,400;10..48,600&family=Inter:wght@500;700&display=swap');

// reset css

/***
    The new CSS reset - version 1.8.5 (last updated 14.6.2023)
    GitHub page: https://github.com/elad2412/the-new-css-reset
***/

/*
    Remove all the styles of the "User-Agent-Stylesheet", except for the 'display' property
    - The "symbol *" part is to solve Firefox SVG sprite bug
    - The "html" attribute is exclud, because otherwise a bug in Chrome breaks the CSS hyphens property (https://github.com/elad2412/the-new-css-reset/issues/36)
 */
*:where(:not(html, iframe, canvas, img, svg, video, audio):not(svg *, symbol *)) {
    all: unset;
    display: revert;
}

/* Preferred box-sizing value */
*,
*::before,
*::after {
    box-sizing: border-box;
}

/* Reapply the pointer cursor for anchor tags */
a, button {
    cursor: revert;
}

/* Remove list styles (bullets/numbers) */
ol, ul, menu {
    list-style: none;
}

/* For images to not be able to exceed their container */
img {
    max-inline-size: 100%;
    max-block-size: 100%;
}

/* removes spacing between cells in tables */
table {
    border-collapse: collapse;
}

/* Safari - solving issue when using user-select:none on the <body> text input doesn't working */
input, textarea {
    -webkit-user-select: auto;
}

/* revert the 'white-space' property for textarea elements on Safari */
textarea {
    white-space: revert;
}

/* minimum style to allow to style meter element */
meter {
    -webkit-appearance: revert;
    appearance: revert;
}

/* preformatted text - use only for this feature */
:where(pre) {
    all: revert;
}

/* reset default text opacity of input placeholder */
::placeholder {
    color: unset;
}

/* remove default dot (•) sign */
::marker {
    content: initial;
}

/* fix the feature of 'hidden' attribute.
   display:revert; revert to element instead of attribute */
:where([hidden]) {
    display: none;
}

/* revert for bug in Chromium browsers
- fix for the content editable attribute will work properly.
- webkit-user-select: auto; added for Safari in case of using user-select:none on wrapper element */
:where([contenteditable]:not([contenteditable="false"])) {
    -moz-user-modify: read-write;
    -webkit-user-modify: read-write;
    overflow-wrap: break-word;
    -webkit-line-break: after-white-space;
    -webkit-user-select: auto;
}

/* apply back the draggable feature - exist only in Chromium and Safari */
:where([draggable="true"]) {
    -webkit-user-drag: element;
}

/* Revert Modal native behavior */
:where(dialog:modal) {
    all: revert;
}

html {
    font-size: 62.5%;
}
body {
    // font-family: 'Archivo', sans-serif;
    font-family: 'Bricolage Grotesque', sans-serif;
    // font-family: 'Inter', sans-serif;
    background-color: #F5F5F5;
    font-size: 1.6rem;
    text-align: left;
}

* {
    margin: 0;
    padding: 0;
}

.container { 
    width: 100%;
    margin: 0 auto;
    padding: 0 21px;
    position: relative;
    z-index: 2;
}

h1 { 
    font-size: 2.3rem;
}
h2 { 
    font-size: 1.3rem;
}
h3 { 
    font-size: 1rem;
}


@media (min-width: 1366px) {
    .container {
        width: 1110px;
        margin: 0 auto;
    }

    h1 { 
        font-size: 4.6rem;
    }
    h2 { 
        font-size: 2.6rem;
    }
    h3 { 
        font-size: 2rem;
    }

}



.title-section {
    padding: 3rem 0;
}

// buttons

.buttonOrder,
.buttonViewMore {
    padding: 16px 32px;
    background-color: #008bbb;
    color: #fff;
    width: fit-content;
    border-radius: 51px;
    margin: 20px 0;
    transition: all .2s ease;
    font-size: 1.4rem;
    font-weight: 100;
    cursor: pointer;
    position: relative;
}

.buttonViewMore {
    padding: 8px 16px;
    width: auto;
    &:hover {
        background: #025b78;
        box-shadow: 0px 0px 35px -7px rgba(255, 255, 255, 0.5);
    }
}

.buttonWhats { 
    background: #fff;
    padding-left: 70px;
    color: #008bbb;
    &::before {
        content: " ";
        background: url(../images/whatsappicon.svg) 0 0 no-repeat;
        background-size: 100%;
        width: 30px;
        height: 30px;
        position: absolute;
        left: 8%;
        top: 50%;
        transform: translateY(-50%);
    }
}

.seeAll {
    margin: 3vh auto;
    width: fit-content;
    display: block;
}

.icon-arrow {
    background: url(../../assets/images/arrow-icon.svg);
    width: 45px;
    height: 45px;
    background-size: 100%;
    transition: all .2s ease;
    opacity: 0.6;
    cursor: pointer;

    &:hover { 
        opacity: 1;
        transform: scale(1.1);
    }
    
}

.textLink { 

    position: relative;
    transition: all .2s ease;
    cursor: pointer;

    &::before { 
        content: " ";
        position: absolute;
        height: 1px;
        background: #000;
        left: 0;
        bottom: 0;
        transition: all .2s ease;
        width: 0px;
    }

    &:hover {


        &::before {
            width: 100%;
        }
    }
}

// scroll block

.scroll-block {
    overflow: hidden;
}
// flex`s

.d-flex {
    display: flex;
}
.justify-content-between {
    justify-content: space-between;
}

// padding`s

.p-3 {
    padding: 3rem;
}
.px-4 {
    padding: 4rem 0;
}

// order desktop
@media (min-width: 1336px) {
    .order-1 { 
        order: 1;
    }
    .order-2 {
        order: 2;
    }
}



@media (max-width: 576px) {
    .p-sm-2 {
        padding: 2rem;
    }
    .flex-sm-column {
        flex-direction: column;
    }
}