.pageError {
    padding: 10rem 0;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 40vh;
    flex-direction: column;
    span {
        font-size: 23rem;
        font-weight: 700;
        margin-bottom: 5rem;
        color: #008bbb;
        background: url(../../assets/images/rede-fibra-optica.jpg);
        background-clip: text;
        -webkit-background-clip: text;
        color: transparent;
        letter-spacing: -10px;
    }
}

