.partner {
    .bannerPartner { 
        background: url(../../assets/images/partner.jpg) 0 0 no-repeat;
        height: 46vh;
        background-size: cover;
        background-position: 0 70%;

        align-items: center;
        display: flex;
    }
    .infos {

    }
    h1 {
        font-size: 3.5rem;
    }
    h1,span {
        color: #fff;
    }
    span {
        display: block;
        max-width: 100%;
        font-weight: 100;
    }
}

@media (min-width: 768px) {
    .partner {
        h1 {
            font-size: 5.6rem;
        }
        span {
            max-width: 70%;
        }
    }
}
