.footer {
    display: flex;
    padding: 4rem 0;
    background: url(../../assets/images/Footer-Background.jpg) 0 0 no-repeat;
    background-size: cover;


    .col { 
        width: 100%;
        padding: 1.5rem;

        .logoFooter {
            margin-bottom: 1rem ;

            img {
                width: 180px;
            }
            
        }
    }

    .logo { 
        display: flex;
        flex-direction: column;
        order: 3;
        span { 
            font-weight: 100;
            font-size: 1.2rem;
            margin: 2px 0;
        }
    }

    .menuFooter { 
        display: flex;
        flex-direction: column;
        order: 1;
        a { 
            padding: 4px 0;
        }
    }

    .socials {
        order: 3;
        img {
            width: 45px;
            height: 45px;
        }

        h3 {
            margin-bottom: 1rem;;
        }
    }
}

.containerFooter {
    display: flex;
    flex-direction: column;
}
.footer-bottom {
    background: #008bbb;
    font-size: 1rem;
    padding: 1rem 0;
    color: #fff;
    text-align: center;
}

@media (min-width: 768px) {
    .containerFooter {
        flex-direction: row;
    }

    .footer {
        .logo {
            order: 1;
        }

        .menuFooter {
            order: 2;
        }

        .socials {
            order: 3;
        }
        .col {
            width: 33.333%;

            &:not(:first-child) {
                border-left: 1px solid #00000015;
            }

        }
    }
    
}
