.quem-somos {
    .left, .right {
        width: 100%;
    }
    .text { 
        margin: 15px 0;
        font-weight: 100;
    }
}
@media (min-width: 768px) {
    .quem-somos {
        .left, .right {
            width: 48%;
        }
        .text { 
            margin: 15px 0;
            font-weight: 100;
        }
    }
}

