button {

    border: 0;
    outline: 0;
    margin: 0 3px;
    background: unset;
    cursor: pointer;

    img {
        width: 35px;
        transition: all .2s ease;
    }
    &:hover { 
        img { 
            transform: scale(1.1);
        }
    }
}