.formContact {
    width: 100%;
    max-width: 790px;
    margin: 5rem auto;
    padding: 0 2rem;

    .group {    
        display: flex;
        flex-direction: column;
        margin: 10px 0;
        font-weight: 100;
        font-size: 1.2rem;
    }

    input, textarea {
        border-radius: 4px;
        border: 1px solid #ddd;
        margin: 10px 0;
        padding: 6px 12px;
        color: #666;
        height: 50px;
        transition: all .2s ease;

        &:focus {
            border-color: #008bbb;

        }

        &::placeholder {
            color: #0000004a;
        }
    }

    textarea {
        height: 120px;
    }
    .buttonSubmit {
        border-radius: 4px;
        height: 50px;
        width: 100%;
        text-align: center;
        font-weight: 400;
    }
    
}

@media (min-width: 768px) {
    .formContact {

        .group {
            font-size: 1.6rem;
        }
    }
}