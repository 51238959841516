.landingpage {
    .header,
    .footer,
    .footer-bottom {
        display: none;
    }
}

.carousel-bg {
    width: 100vw;
    height: auto;
    background: url(../../assets/images/bg-usinarfo.png) center center no-repeat;
    background-size: cover;
    padding: 3rem 0;

    img {
        max-width: 50%;
        margin: 0 auto;
    }

    .container {
        display: flex;
        flex-direction: column;
    }
}
.titlelp {
    color: #fff;
    font-weight: 500;
    filter: drop-shadow(4px 4px 2px rgba(0, 0, 0, 0.2));
    padding: 2rem 0;
    font-size: 2.5rem;
}
strong {
    color: #008bbb;
    display: inline-block;
    background-color: rgba(255,255,255,0.7);
    padding: 8px 16px;
    border-radius: 4px;
    filter: drop-shadow(0,0,0,0);
}
.formLead {
    width: 100%;
    padding: 2rem;
    .form-group {
        margin-bottom: 2rem;
        &:last-child {
            margin-bottom: 1rem;
        }
    }
    label { 
        display: block;
        margin-bottom: 0.5rem;
        font-size: 1.2rem;
    }
    input { 
        border: 1px solid #ccc;
        border-radius: 4px;
        height: 45px;
        width: 100%;
        padding: 0 15px;
        color: #666;
        font-weight: 300;
        transition: all .2s ease;
        &:focus{
            border-color: #008bbb;
            background: #008cbb13;
        }
    }
    h2 { 
        margin-bottom: 3rem;
        color: #008bbb;
        font-size: 2rem;
    }
    button {
        background: #008bbb;
        color: #fff;
        font-size: 1.2rem;
        width: 100%;
        display: block;
        padding: 18px;
        text-align: center;
        margin: 0;
        border-radius: 4px;
        transition: all .2s ease;
        text-transform: uppercase;
        &:hover {
            background: rgb(5, 168, 5);
        }
    }
}
.desktop {
    display: none;
}
.serviceslp {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(4, 1fr);
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    margin: 0 -21px;
    display: none;
    .item {
        height: 170px;

        h2 {
            color: #008bbb;
            background-color: #fff;
            padding: 1rem;
        }

        &-one {
            background: url(../../assets/images/cabeamento-estruturado-rfo.jpg) 0 0 no-repeat;
            background-size: cover;
        }
        &-two {
            background: url(../../assets/images/rede-fibra-optica.jpg) 0 0 no-repeat;
            background-size: cover;
        }
        &-three {
            background: url(../../assets/images/certified-network.jpeg) 0 0 no-repeat;
            background-size: cover;
        }
        &-four {
            background: url(../../assets/images/maintence-network.jpg) 0 0 no-repeat;
            background-size: cover;
        }
    }
    
}

.leadsuccess {
    img {
        max-width: 50%;
    }
    p {
        line-height: 14px;
    }
    strong {
        padding: 2rem 0;
        background: 0;
    }
}
@media (min-width: 769px) {

    .desktop {
        display: grid;
    }
    .landingpage {
        overflow-y: hidden;
    }

    .carousel-bg {
        width: 70vw;
        height: 100vh;
        
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        overflow-y: auto;
        img {
            padding: 5rem 5rem 0rem 5rem;
            max-width: 300px;
            margin: 0;
        }

        .container {
            padding: 2rem;
        }
    }
    
    .formLead {
        top: 0;
        position: absolute;
        right: 0;
        height: 100vh;
        background: #fff;
        padding: 5rem;
        width: 30vw;
        z-index: 2;
        box-shadow: 10px 10px 35px 2px #000;
        display: flex;
        justify-content: center;
        h2 { 
            margin-bottom: 3rem;
            color: #008bbb;
            font-size: 2.5rem;
        }
       
    }
    .leadsuccess {
        
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        p {
            font-size: 1.6rem;
            line-height: 22px;
            text-align: center;
        }

        strong {
            color: #008bbb;
            font-weight: 900;
            font-size: 2rem;
            display: inline-flex;
            padding: 0;
            background-color: #fff;
            padding: 2rem;
        }
        img {
            max-width: 200px;
            margin-bottom: 2rem;
        }
    }
   
    .titlelp {
        padding: 5rem 5rem 10rem 5rem;
        font-size: 3.5rem;
    }

    
    .serviceslp {
        display: flex;
        justify-content: space-around;

        .item {
            background-color: #fff;
            display: flex;
            align-items: flex-end;
            min-height: 200px;
            width: 22%;
            box-shadow: 5px 5px 22px 5px rgba(0,0,0,.3);

           
            h2 {
                font-size: 1.4rem;
                color: #008bbb;
                background: #fff;
                padding: 1rem 2rem;
                display: flex;
                text-align: center;
                width: 100%;
                min-height: 52px;
                align-items: center;
                justify-content: center;
            }
        }
    }


}