.header { 
    background: rgba(255,255,255,.7);
    box-shadow: 0px 0px 15px -6px rgba(0, 0, 0, 0.5);
    padding: 1rem 0;
    position: sticky;
    top: 0;
    width: 100vw;
    z-index: 3;
    justify-content: space-between;

    .bars {
        width: 25px;
        height: 25px;
        display: flex;
        justify-content: center;
        flex-direction: column;
        span {
            width: 100%;
            height: 2px;
            background-color: #008bbb;
            margin: 2px 0;
            display: block;
        }
    }

    .container { 
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .logo {
        img { 
            max-width: 90px;
        }
    }

}   

.menu { 

    display: none;
    a {
        margin: 0 15px;
        color: #fff;
        text-decoration: none;
        position: absolute;
        padding: 1rem;

        // &::before {

        //     content: " ";
        //     position: absolute;
        //     width: 120%;
        //     background: #008bbb;
        //     height: 20px;
        //     bottom: -65px;
        //     left: 50%;
        //     transform: translateX(-50%);
        //     border-radius: 15px;
        //     transition: all .2s ease;
        // }

        &:hover::before {
            bottom: -45px;
        }
    }

}

.menu-mobile {
    transition: all .2s ease;
    background:  #008bbb;
    height: 100vh;
    display: flex;
    flex-direction: column;
    padding: 8rem 5rem 5rem 5rem;
    text-align: right;
    font-size: 3.2rem;
    color: #fff;
    position: absolute;
    width: 100%;
    z-index: 4;
    top: 0;
    .close {
        margin-bottom: 5rem;
    }
    a { 
        padding: 1.5rem 0;
    }
}

@media (min-width: 768px) {
    .header {
        .logo {
            img {
                max-width: 120px;
            }
        }

        .bars {
            display: none;
        }
    }
    .menu-mobile {
        display: none;
    }
    .menu {
        display: flex;
        a {
            margin: 0 5px;
            color: #333;
            text-decoration: none;
            position: relative;
            font-size: 1.2rem;
            font-weight: 100;
        }
    }
}

@media (min-width: 768px) {
    .menu {

        a {
            font-size: 1.6rem;
        }
    }
}