.wrapService {
    padding: 2rem 0;
}
.service {
    display: flex;
    margin: 2rem 0;
    flex-direction: column;
    .image {
        width: 100%;
        min-height: 200px;
        height: 100%;
        border-radius: 4px;
    }
    .one {
        background: url(../../assets/images/cabeamento-estruturado-rfo.jpg) 0 0 no-repeat;
        background-size: cover;
    }
    .two {
        background: url(../../assets/images/fusao-fibra-optica.png) 0 0 no-repeat;
        background-size: cover;
    }
    .three {
        background: url(../../assets/images/rede-fibra-optica.jpg) 0 0 no-repeat;
        background-size: cover;
    }

    .four {
        background: url(../../assets/images/certified-network.jpeg) 0 0 no-repeat;
        background-size: cover;
    }
    .five {
        background: url(../../assets/images/maintence-network.jpg) 0 0 no-repeat;
        background-size: cover;
    }

    .text {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 0;
        position: relative;
        top: -40px;


        h2 {
            margin-bottom: 1rem;
            color: #fff;
            background: #008bbb;
            font-size: 2.2rem;
            padding: 10px;
        }

        span {
            font-weight: 100;
        }
    }
}

@media (min-width: 768px) {
    .service {
        
        .image { 
            height: 350px;
        }
    }
    .wrapService {
        padding: 5rem 0;
    }
}

@media (min-width: 1336px) {
    .service {
        flex-direction: row;

        .text {
            top: unset;
            padding: 3rem;
            h2 { 
                background: unset;
                color:#008bbb;
                padding: 0;
            }
        }
    }
}